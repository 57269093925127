import React, { useContext, useEffect, useRef } from 'react'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SearchableDropdown from '../UI/SearchableDropdown'
import { ScenarioContext } from '../../context/scenarioContext'

const SearchModal = ({ show, onClose, onChange }) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const { scenarioDetail } = useContext(ScenarioContext)

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus()
    }
  }, [show, inputRef.current])

  const data = scenarioDetail.scenes.map((scene) => ({
    render: (
      <div className="text-sm font-bold flex items-center">
        {scene.number}. {scene.name}
      </div>
    ),
    value: `${scene.name} ${scene.number}`,
    id: scene.id,
  }))

  return (
    <Modal id="search-modal" show={show} maxWidth="xl" closeButton={false} onClose={onClose}>
      <div className="-mx-14">
        <SearchableDropdown
          placeholder={`${t('search_scene', 'Search on scene number or title')}...`}
          data={data}
          show={show}
          minLength={1}
          hideHandler={onClose}
          keyboardNavigation
          ref={inputRef}
          clickHandler={(option) => {
            onChange(option.id)
          }}
          sortOnSearchKey="value"
        />
      </div>
    </Modal>
  )
}

SearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SearchModal
