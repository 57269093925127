import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidV4 } from 'uuid'
import { ELEMENT_TYPES, getPointClass } from '../../helpers/elementHelper'
import TestButton from './TestButton'
import { FaArrowRight } from 'react-icons/fa'
import { capitalized } from '../../helpers/javascript'
import { SceneContext } from '../../context/sceneContext'
import { ScenarioContext } from '../../context/scenarioContext'

const NodeElementCard = ({ element: parentElement, answerElements }) => {
  const { handleSceneChange, handleEnding, scene, dispatchAttempt } = useContext(SceneContext)
  const { scenarioDetail } = useContext(ScenarioContext)

  const renderCard = (element) => {
    const elRow = ELEMENT_TYPES.find((el) => el.type === element.kind)
    if (!elRow) return <></>

    const renderLabel = () => {
      return (
        <>
          <header className="font-bold">
            {capitalized(elRow.title)} {element.letterLabel}
          </header>
          {element.label && <p className="text-warp-gray mt-1">{element.label}</p>}
        </>
      )
    }

    return (
      <div key={element.id} className={element.groupUuid ? 'mt-3.5' : ''}>
        <div className="flex items-center justify-between">
          <h4 className="text-normal mb-0 ml-1">{renderLabel()}</h4>
          <div className="flex items-center">
            {element.points > 0 && (
              <span
                className={`text-warp-dark-light font-bold text-sm w-6 h-6 rounded-50 flex overflow-hidden items-center justify-center
                  ${getPointClass(element.points, scenarioDetail.scoringSystem.kind)}`}
              >
                {element.points}
              </span>
            )}

            {(element.linkToId || element.linkToEnding) && (
              <TestButton
                onClick={() => {
                  dispatchAttempt({
                    type: 'CHOICE',
                    value: { sceneId: scene.id, answerId: element.id },
                    uuid: uuidV4(),
                  })
                  element.linkToEnding ? handleEnding() : handleSceneChange(element.linkToId)
                }}
                width={36}
              >
                <FaArrowRight className="text-xl text-warp-dark-light font-bold" />
              </TestButton>
            )}
          </div>
        </div>
        {element.randomizedLinkToIds.map((sceneId) => (
          <div key={sceneId} className="flex items-center justify-between mt-2.5">
            <h4 className="text-normal mb-0 ml-1">Random</h4>
            {sceneId && (
              <TestButton onClick={() => handleSceneChange(sceneId)} width={36}>
                <FaArrowRight className="text-xl text-warp-dark-light font-bold" />
              </TestButton>
            )}
          </div>
        ))}
      </div>
    )
  }

  if (!parentElement.groupUuid) return renderCard(parentElement)

  const groupElRow = ELEMENT_TYPES.find((el) => el.type === parentElement.groupKind)
  if (!groupElRow) return <></>

  return (
    <div>
      <header className={`flex-container align-middle text-stable-dark`}>
        <h4 className="font-bold mb-0 ml-1 text-warp-gray">{capitalized(groupElRow.title)}</h4>
      </header>
      {renderCard(parentElement)}
      {answerElements.map((el) => renderCard(el))}
    </div>
  )
}

NodeElementCard.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    label: PropTypes.string,
    points: PropTypes.number,
    linkToId: PropTypes.string,
    linkToEnding: PropTypes.bool,
    randomizedLinkToIds: PropTypes.arrayOf(PropTypes.string),
    groupUuid: PropTypes.string,
    groupKind: PropTypes.string,
  }).isRequired,
  answerElements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      label: PropTypes.string,
      points: PropTypes.number,
      linkToId: PropTypes.string,
      linkToEnding: PropTypes.bool,
      randomizedLinkToIds: PropTypes.arrayOf(PropTypes.string),
      groupUuid: PropTypes.string,
      groupKind: PropTypes.string,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
}

export default NodeElementCard
