import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../UI/Tooltip/Tooltip'

const TestButton = ({
  title,
  onClick = () => {},
  children,
  tooltipPosition = 'top',
  className,
  width = 48,
  disabled = false,
}) => {
  const getIinner = () => (
    <button
      onClick={onClick}
      type="button"
      disabled={disabled}
      className={`grid place-content-center bg-white/80 hover:bg-white cursor-pointer hover:transition-all ml-2.5 rounded-50 
        ${className}`}
      style={{
        width: `${width}px`,
        aspectRatio: '1 / 1',
      }}
    >
      {children}
    </button>
  )

  if (!title) return getIinner()

  return (
    <Tooltip position={tooltipPosition} title={title}>
      {getIinner()}
    </Tooltip>
  )
}

TestButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  tooltipPosition: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  width: PropTypes.number,
  disabled: PropTypes.bool,
}

export default TestButton
