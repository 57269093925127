import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { sortOnKey } from '../../helpers/format'
import { useTranslation } from 'react-i18next'

const SearchableDropdown = forwardRef(
  (
    {
      placeholder,
      style,
      data,
      clickHandler,
      show = true,
      notFoundTitle,
      notFoundText,
      hideHandler,
      onChangeSearchKey,
      footer,
      minLength = 0,
      keyboardNavigation,
      sortOnSearchKey,
    },
    outerRef
  ) => {
    const { t } = useTranslation()
    const [filtered, setFiltered] = useState([])
    const ref = useRef(null)
    const inputRef = useRef(null)
    const filteredRef = useRef()
    useImperativeHandle(outerRef, () => inputRef.current, [])

    const handleClickOutside = (e) => {
      if (ref.current?.contains(e.target)) {
        return null
      }
      e.preventDefault()
      return show ? hideHandler?.() : null
    }

    const changeHandler = (value) => {
      let newFilter = data

      if (value.length >= minLength) {
        newFilter = data.filter((item) => {
          const words = value.trim().toLowerCase().split(' ')
          const searchable = item.value.toString().toLowerCase()
          return searchable.includes(value) || words.every((word) => searchable.includes(word))
        })
        if (sortOnSearchKey) newFilter = sortOnKey(newFilter, sortOnSearchKey)
      }

      setFiltered(newFilter)

      if (onChangeSearchKey) onChangeSearchKey(value)
    }

    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'Escape':
          e.preventDefault()
          e.stopPropagation()
          if (inputRef.current.value === '') {
            hideHandler?.()
          }
          inputRef.current.value = ''
          inputRef.current?.focus()
          changeHandler('')
          break

        case 'ArrowDown':
          if (keyboardNavigation) {
            e.preventDefault()
            const nextItem = ref.current.querySelector(
              '.o-dropdown__search--list--item.focus + div'
            )
            if (nextItem) {
              ref.current
                .querySelector('.o-dropdown__search--list--item.focus')
                ?.classList?.remove('focus')
              nextItem.classList.add('focus')
            }
          }
          break

        case 'ArrowUp':
          if (keyboardNavigation) {
            e.preventDefault()
            const prevItem = ref.current.querySelector(
              '.o-dropdown__search--list--item:has(+ .o-dropdown__search--list--item.focus)'
            )
            if (prevItem) {
              ref.current
                .querySelector('.o-dropdown__search--list--item.focus')
                ?.classList?.remove('focus')
              prevItem.classList.add('focus')
            }
          }
          break

        case 'Enter':
          if (keyboardNavigation) {
            e.preventDefault()
            const focusedItem = ref.current.querySelector('.o-dropdown__search--list--item.focus')
            if (focusedItem) {
              const index = Array.from(focusedItem.parentNode.children).indexOf(focusedItem)
              // weired bug: filtered is undefined here but ref is fine
              const selectedItem = filteredRef.current[index]
              clickHandler(selectedItem)
            }
          }
          break
      }
    }

    const addRemoveEvents = (add) => {
      if (add) {
        setTimeout(() => {
          document.addEventListener('click', handleClickOutside)
        }, 0)
        inputRef.current.addEventListener('keydown', handleKeyPress)
      } else {
        document.removeEventListener('click', handleClickOutside)
        document.removeEventListener('keydown', handleKeyPress)
      }
    }

    useEffect(() => {
      if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

      addRemoveEvents(show)
      return () => {
        addRemoveEvents(false)
      }
    }, [show])

    useEffect(() => {
      if (inputRef.current?.value) {
        changeHandler(inputRef.current.value)
      } else {
        setFiltered(data)
      }
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }, [data])

    useEffect(() => {
      if (ref.current && filtered.length) {
        ref.current
          .querySelector('.o-dropdown__search--list--item:first-child')
          ?.classList?.add('focus')
      }
    }, [filtered, ref.current])

    useEffect(() => {
      filteredRef.current = filtered
    }, [filtered])

    if (!show) return null

    const createRows = () => {
      const searchKey = inputRef.current?.value ?? ''
      const rendered = [...filtered].map((item, i) => (
        <div
          data-testid={`rendered-${i}`}
          tabIndex="0"
          key={i}
          onClick={() => {
            clickHandler(item)
          }}
          onKeyDown={(e) => e.key === 'Enter' && clickHandler(item)}
          className="group o-dropdown__search--list--item cursor-pointer relativet"
        >
          <div className="py-2 px-6 text-sm text-warp-dark-ligh flex items-center group-[.focus]:bg-[#e7461a26]">
            {item.render}
          </div>
        </div>
      ))
      if (!rendered.length) {
        if (searchKey) {
          return (
            <div className="o-dropdown__search--empty--container ">
              <span className="text-center">
                <div className="text-warp-gray font-bold">
                  {t('no_results_for', 'No results for')}{' '}
                  <span className="text-warp-dark-light font-bold">{inputRef.current.value}</span>
                </div>
                <div className="text-warp-gray text-center text-sm">
                  {t('try_search_other', 'Try searching on other keywords')}
                </div>
              </span>
            </div>
          )
        }
        return (
          <div className="o-dropdown__search--empty--container">
            <span className="text-stable-dark text-center">
              <div className="text-stable-dark font-bold mb-1">{notFoundTitle}</div>
              {notFoundText}
            </span>
          </div>
        )
      }
      return <>{rendered}</>
    }
    return (
      <div id="search-dropdown" ref={ref} className="relative">
        <div
          style={style}
          className={`rounded shadow-md flex flex-col w-full max-h-[290px] border-warp-gray bg-white`}
          tabIndex="-1"
        >
          <span
            className={`block py-1 w-full text-black sticky border-b-warp-stable border-b border-solid
              ${inputRef.current?.value?.length < minLength ? 'border-0' : ''}`}
          >
            <span className="!w-full flex text-warp-gray h-[42px] max-w-full rounded bg-white mr-5 transition-all border-0">
              <span
                data-testid="search-icon"
                className="cursor-pointer flex items-center justify-center min-w-[42px] h-full"
                onClick={(e) => {
                  e.stopPropagation()
                  inputRef.current.focus()
                }}
              >
                <FaSearch />
              </span>
              <input
                className="pl-0 mb-0 bg-transparent w-full h-full pr-0 tracking-light border-0"
                autoComplete="off"
                data-testid="input-field"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  inputRef.current.focus()
                }}
                ref={inputRef}
                type="text"
                defaultValue={inputRef.current?.value}
                onChange={(e) => changeHandler(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && filtered.length === 1) {
                    clickHandler(filtered[0])
                  }
                }}
                placeholder={placeholder}
              ></input>
              {inputRef.current?.value !== '' && (
                <span
                  data-testid="suffix-icon"
                  className="w-[42px] flex items-center justify-center text-sm overflow-hidden text-warp-gray transition-all cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    inputRef.current.value = ''

                    setFiltered(data)
                    return inputRef.current.focus()
                  }}
                >
                  <FiX />
                </span>
              )}
            </span>
          </span>
          {(!inputRef.current || inputRef.current?.value?.length >= minLength) && (
            <div data-testid="list-items" className="py-2.5 overflow-auto h-full" tabIndex="-1">
              {createRows()}
            </div>
          )}
          {footer && (
            <div
              data-testid="footer"
              className="o-dropdown__search--footer cursor-pointer text-sm border-top"
            >
              {footer}
            </div>
          )}
        </div>
      </div>
    )
  }
)

SearchableDropdown.propTypes = {
  placeholder: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      render: PropTypes.node.isRequired,
    })
  ).isRequired,
  clickHandler: PropTypes.func.isRequired,
  show: PropTypes.bool,
  notFoundTitle: PropTypes.string,
  notFoundText: PropTypes.node,
  hideHandler: PropTypes.func,
  onChangeSearchKey: PropTypes.func,
  footer: PropTypes.node,
  minLength: PropTypes.number,
  keyboardNavigation: PropTypes.bool,
  sortOnSearchKey: PropTypes.string,
}

SearchableDropdown.displayName = 'SearchableDropdown'

export default SearchableDropdown
