import React from 'react'
import { FaChevronCircleDown } from 'react-icons/fa'
import PropTypes from 'prop-types'

const Collapsible = ({ title, right, children }) => {
  return (
    <>
      <Collapsible.Header title={title} right={right} />
      <Collapsible.Content>{children}</Collapsible.Content>
    </>
  )
}

export const CollapsibleHeader = ({ title, right, className = '', isOpen = false, setIsOpen }) => {
  const toggleCollapsible = () => setIsOpen(!isOpen)

  return (
    <header
      className={`flex items-center cursor-pointer justify-between peer ${className} ${isOpen ? 'collapsible-open' : ''}`}
      onClick={toggleCollapsible}
    >
      <h3 className="font-bold">{title}</h3>
      <div className="flex items-center">
        {right && <div className="mr-5">{right}</div>}
        <FaChevronCircleDown className={`transition-all ${isOpen ? 'rotate-180' : ''}`} />
      </div>
    </header>
  )
}

export const CollapsibleContent = ({ children }) => (
  <section className="transition-all max-h-0 overflow-hidden peer-[.collapsible-open]:max-h-[5000px]">
    {children}
  </section>
)

CollapsibleContent.propTypes = {
  children: PropTypes.node.isRequired,
}
CollapsibleContent.displayName = 'Collapsible.Content'

CollapsibleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.node,
  className: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}
CollapsibleHeader.displayName = 'Collapsible.Header'

Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default Collapsible
