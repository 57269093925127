import React from 'react'
import PropTypes from 'prop-types'
import { getFontUrl } from '../../../../helpers/fonts'

const TextBubble = ({
  textValue,
  font,
  id,
  width = 1.8,
  type = 'default',
  bottomSpacing = '0.15',
}) => {
  if (!textValue) return null

  return (
    <a-entity
      text-bubble={`enabled: true; type:${type}; width: ${width}; bottomSpacing: ${bottomSpacing}`}
      key={id}
      id={id}
      position="0 0 0"
    >
      <a-text
        id={`${id}-text`}
        font={getFontUrl(font)}
        negate="false"
        shader="msdf"
        value={textValue}
        color="black"
        anchor="center"
        align="center"
        position="0 0 0.03"
        material="visible:false"
        geometry="primitive:plane; width:auto;height:auto"
        width={width - 0.2}
        wrap-count={width > 1.8 ? width * 8.4 : 14}
        letter-spacing={-2}
      />
      <a-entity id="bubble" />
    </a-entity>
  )
}

export default TextBubble

TextBubble.propTypes = {
  textValue: PropTypes.string.isRequired,
  font: PropTypes.string.isRequired,
  width: PropTypes.number,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  bottomSpacing: PropTypes.string,
}
