import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { getPointClass } from '../../helpers/elementHelper'
import { ScenarioContext } from '../../context/scenarioContext'

const PointsCircle = ({ points }) => {
  const { scenarioDetail } = useContext(ScenarioContext)

  return (
    <span
      className={`text-warp-dark-light font-bold text-sm w-6 h-6 rounded-50 flex overflow-hidden items-center justify-center
    ${getPointClass(points, scenarioDetail.scoringSystem.kind)}`}
    >
      {points}
    </span>
  )
}

PointsCircle.propTypes = {
  points: PropTypes.number.isRequired,
}

export default PointsCircle
