import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ScenarioContext } from '../context/scenarioContext'
import Stars from '../components/UI/Stars'
import { SceneContext } from '../context/sceneContext'
import { AuthContext } from '../context/authContext'
import calculateScenarioScore from '../helpers/calculateScenarioScore'
import { clamp } from '../helpers/javascript'

const EndingPage = ({ visible }) => {
  const { t } = useTranslation()
  const [stars, setStars] = useState(0)
  const [showStars, setShowStars] = useState(true)
  const [resultMessage, setResultMessage] = useState('')
  const [showBackToLmsWindow, setShowBackToLmsWindow] = useState(false)
  const { colors, scenarioDetail } = useContext(ScenarioContext)
  const {
    isAborted,
    attemptState: { answers: chosenAnswers },
  } = useContext(SceneContext)
  const {
    sessionType: [sessionType],
    lmsName: [lmsName],
  } = useContext(AuthContext)
  const navigate = useNavigate()

  const abortMessage = t('you_stopped_playing', 'You have stopped playing.')
  const calculateScore = () => {
    if (isAborted) {
      setShowStars(false)
      return setResultMessage(abortMessage)
    }
    const { kind: scoringKind, pointsPerStar } = scenarioDetail.scoringSystem
    let descriptionKey = 'noStarDescription'

    if (scoringKind === 'DISABLED') {
      setShowStars(false)
      return setResultMessage(scenarioDetail.scoringSystem[descriptionKey])
    }
    const score = calculateScenarioScore(scenarioDetail, chosenAnswers)

    let tempStars = Number(score).toFixed(2)
    if (scoringKind === 'SUBTRACT') {
      tempStars = Math.ceil(5 - tempStars / Number(pointsPerStar).toFixed(2))
    } else {
      tempStars = Math.ceil((tempStars + 1) / Number(pointsPerStar).toFixed(2)) - 1
    }
    tempStars = clamp(tempStars, 1, 5)

    setStars(tempStars)
    switch (tempStars) {
      case 1:
        descriptionKey = 'oneStarDescription'
        break
      case 2:
        descriptionKey = 'twoStarDescription'
        break
      case 3:
        descriptionKey = 'threeStarDescription'
        break
      case 4:
        descriptionKey = 'fourStarDescription'
        break
      case 5:
        descriptionKey = 'fiveStarDescription'
        break
      default:
        return null
    }
    return setResultMessage(scenarioDetail.scoringSystem[descriptionKey])
  }

  const backToScenariosList = () => {
    const url = new URL(window.location.hash.replace('#', window.location.origin))
    const urlParams = new URLSearchParams(url.search)
    const returnUrl = urlParams.get('returnUrl') ?? '/'
    navigate(returnUrl)
  }

  useEffect(() => {
    if (visible) {
      calculateScore()
    }
  }, [visible])

  if (!visible) return <div />
  return (
    <>
      <div className="bg-black z-1 fixed top-0 left-0 h-screen w-screen" />
      <div className="absolute z-10 grid h-screen w-screen room:w-full place-items-center bg-black text-white shadow-2xl">
        <div className="">
          {!showBackToLmsWindow && (
            <div
              className={`w-fill mx-auto flex h-96 room:h-80 w-full min-w-[420px] flex-row items-center space-x-4 text-black md:min-w-[600px] ${
                sessionType === 'LMS' ? 'lg:min-w-[992px]' : 'lg:min-w-[800px]'
              }`}
            >
              <div
                id="Play result"
                className="mx-auto h-96 room:h-80 flex-grow rounded bg-white p-[30px]"
              >
                <div className="flex h-full flex-col items-center justify-center">
                  {showStars && (
                    <>
                      <h3 className="text-2xl font-bold">{t('your_score', 'Your score')}</h3>
                      <div className="my-6 scale-150">
                        <Stars value={stars} color={colors.primaryColor} />
                      </div>
                    </>
                  )}
                  <div className="grid max-w-[800px] place-items-center text-center text-lg font-bold">
                    {resultMessage}
                  </div>
                </div>
              </div>
              <div id="Buttons" className="flex h-96 room:h-80 w-4/12 flex-col space-y-4">
                <div className="flex-grow rounded bg-white p-[30px]">
                  <h3 className="text-lg font-bold">Scenario</h3>
                  <p>{scenarioDetail.name}</p>
                  {showStars && stars && (
                    <>
                      <h3 className="mt-4 text-lg font-bold">High score</h3>
                      <Stars
                        value={scenarioDetail.highscore < stars ? stars : scenarioDetail.highscore}
                        color={colors.primaryColor}
                      />
                    </>
                  )}
                </div>
                <button
                  type="button"
                  style={{ backgroundColor: colors.primaryColor }}
                  onClick={() => {
                    window.location.reload()
                  }}
                  className={`w-full rounded p-4 font-semibold transition-all button hover:brightness-75 text-${colors.text} `}
                >
                  {t('play_again', 'Play again')}
                </button>

                {sessionType !== 'LMS' && (
                  <button
                    type="button"
                    onClick={backToScenariosList}
                    className={`w-full rounded bg-gray-300 p-4 font-semibold transition-all button hover:bg-gray-500 ${colors.text} `}
                  >
                    {t('close', 'Close')}
                  </button>
                )}

                {sessionType === 'LMS' && (
                  <button
                    type="button"
                    onClick={() => setShowBackToLmsWindow(true)}
                    className={`w-full rounded bg-gray-300 p-4 font-semibold transition-all button hover:bg-gray-500 ${colors.text} `}
                  >
                    Back to {lmsName}
                  </button>
                )}
              </div>
            </div>
          )}

          {showBackToLmsWindow && (
            <div id="Play result" className="mx-auto h-96 w-[600px] rounded bg-white p-[30px]">
              <div className="flex h-full flex-col items-center justify-center text-black">
                <h3 className="mb-3 text-lg font-bold">
                  Go back to {lmsName} to send your result.
                </h3>
                <p className="text-gray-500 opacity-50">You can now close this browser window.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default EndingPage

EndingPage.propTypes = {
  visible: PropTypes.bool.isRequired,
}
