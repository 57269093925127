import React, { useContext, useEffect, useState } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LoadContext } from '../context/loadContext'

const LoadingPage = ({ visible = true }) => {
  const { t } = useTranslation()
  const { loading } = useContext(LoadContext)
  const [loadingTakesTooLong, setLoadingTakesTooLong] = useState(false)
  let timer

  useEffect(() => {
    const seconds = 20
    if (!loading.doneLoading) {
      timer = setTimeout(() => setLoadingTakesTooLong(true), seconds * 1000)
    } else {
      clearTimeout(timer)
      timer = null
    }
  }, [loading])

  if (!loading) return null
  if (!visible) return null

  return (
    <div
      className={` 
      ${visible ? 'absolute z-10 h-screen w-screen bg-black text-white' : 'hidden'}`}
    >
      <div className="container mx-auto grid h-screen place-items-center text-center room:pr-[1000px] room:m-0">
        <div>
          {loadingTakesTooLong ? (
            <>
              <div className="mb-4 flex flex-row items-center space-x-4 text-2xl">
                <FaExclamationTriangle className="text-yellow-500" />
                <span className="font-bold">Could not start an attempt</span>
                <FaExclamationTriangle className="text-yellow-500" />
              </div>
              <button
                type="button"
                className="my-2 rounded bg-white p-4 font-bold text-black hover:bg-slate-200 "
                onClick={() => window.location.reload()}
              >
                Click here to try again
              </button>
            </>
          ) : (
            <h1 className="mb-4 animate-pulse-slowly text-2xl">
              {t('loading_scenario', 'Loading scenario')}
            </h1>
          )}
        </div>
      </div>
    </div>
  )
}

export default LoadingPage

LoadingPage.propTypes = {
  visible: PropTypes.bool,
}
