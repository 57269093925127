import { capitalized } from './javascript'

export const GROUP_CHILD_ELEMENT_TYPES = ['ANSWER', 'BUTTON']
export const INTERACTIVE_ELEMENT_TYPES = [
  'DIRECTION',
  'HOTSPOT',
  'BUTTON',
  'ANSWER',
  'TIMER',
  'TRANSITION',
]

export const ELEMENT_TYPES = [
  { type: 'MPC', title: 'Multiple choice' },
  { type: 'INFORMATION', title: 'Information' },
  { type: 'DIRECTION', title: 'Direction' },
  { type: 'HOTSPOT', title: 'Hotspot' },
  { type: 'BUTTON', title: 'Button' },
  { type: 'QUESTION', title: 'Question', hide: true },
  { type: 'ANSWER', title: 'Answer', hide: true },
  { type: 'TIMER', title: 'Timer' },
  { type: 'TRANSITION', title: 'Auto-transition' },
  { type: 'TOOLTIP', title: 'Tooltip' },
  { type: 'RANDOMIZER', title: 'Randomizer', hide: true },
]

export const getDisplayedNodeElements = (nodeData) => {
  let nextParentIndex = 0
  const allAnswerElements = nodeData.elements.filter(
    (el) => GROUP_CHILD_ELEMENT_TYPES.includes(el.kind) && el.groupUuid
  )

  let elements = nodeData.elements
    .filter((el) => !allAnswerElements.find((aEl) => aEl.id === el.id))
    .sort((a, b) => (a.number === b.number ? a.id - b.id : a.number - b.number))

  return elements.map((el) => {
    let answerElements = allAnswerElements.filter(
      (a) => el.groupUuid && a.groupUuid === el.groupUuid
    )
    const elementHasLetter =
      INTERACTIVE_ELEMENT_TYPES.includes(el.kind) || (el.groupUuid && answerElements.length)

    const parentIndex = nextParentIndex
    nextParentIndex +=
      (elementHasLetter ? 1 : 0) + (answerElements.length ? answerElements.length - 1 : 0)

    answerElements = answerElements.map((ae, childIndex) => ({
      ...ae,
      letterLabel: INTERACTIVE_ELEMENT_TYPES.includes(ae.kind)
        ? getLetterFromNumber(parentIndex + (childIndex ?? 0) + 1)
        : '',
    }))

    return {
      ...el,
      index: parentIndex,
      label: el.label ? el.label : capitalized(el.kind),
      letterLabel: INTERACTIVE_ELEMENT_TYPES.includes(el.kind)
        ? getLetterFromNumber(parentIndex + 1)
        : '',
      answerElements,
    }
  })
}

export const getLetterFromNumber = (number) => {
  let result = ''
  while (number > 0) {
    let remainder = (number - 1) % 26
    result = String.fromCharCode(65 + remainder) + result
    number = Math.floor((number - 1) / 26)
  }
  return result
}

export const getPointClass = (points, scoringSystem) => {
  const classes = [
    'bg-warp-gray',
    'bg-warp-royal',
    scoringSystem === 'SUBTRACT' ? 'bg-warp-red' : 'bg-warp-success',
  ]
  if (scoringSystem === 'DISABLED') return classes[0]
  if (points > 2) return 'bg-warp-balanced'

  return classes[points ?? 0]
}
